import { css } from 'lit';
/* eslint-disable max-lines */
import get from 'lodash-es/get';
import HTTPMethod from 'http-method-enum';
import { Action } from 'redux';
import debounce from 'lodash-es/debounce';
import { ThunkDispatch } from 'redux-thunk/es/types';
import isSvg from 'is-svg';
import { GeoJSONGeometry, stringify } from 'wellknown';
import { EventTypeConfig } from '@/config/ConfigREMEventTypes';
import {
	CreatePreviewDto,
	DraftEvent,
	EventStatus,
	RespondingUnitDto,
	RoadEventDto,
	RoadEventFieldDto,
	RoadEventTimelineDto,
	RoadwayEventMessagesDto,
	SignDto,
	UserPermissions,
	getEmptyDraftEvent,
	getEmptyLaneBlockage,
} from '../../../typings/api';
import { APIErrorJSON, NotificationErrorType } from '../../../typings/shared-types';
import ConfigCARSx, { APIConfig, DebuggingConfig } from '../../config/ConfigCARSx';
import {
	CARS_EVENT_INTEGRATION_ENABLED,
	ConfigREMApi,
	ConfigREMEventForm,
	DRAFT_PROP_PATHS_THAT_INVALIDATE_DESCRIPTION,
	DRAFT_PROP_PATHS_THAT_INVALIDATE_DMS_PREVIEW,
	DRAFT_PROP_PATHS_THAT_INVALIDATE_ICON,
	DRAFT_PROP_PATHS_THAT_INVALIDATE_LOCATION_DETAILS,
	FUTURE_EVENT_ICON_THRESHOLD,
	REM_FORM_SECTIONS,
} from '../../config/ConfigREM';
import { AppSection, HttpStatusCode, MessageStates, REMView } from '../../constants';
import { isAPIErrorJSON } from '../../utils/type-guards';
import { isValidNumber, isValidString } from '../../utils/utils';
import _isNotEqual from '../../utils/_isNotEqual';
import APIRequest, { APIError, APIRequestReturn, getAPIHeaders } from '../APIRequest';
import { addItem, removeItem } from '../redux-loaderAnim';
import { navigate } from '../redux-routing';
import store, { RootAction, RootState, ThunkActionRoot } from '../redux-store';
import { showMainBanner } from '../redux-ui';
import { REMActionType } from './rem-actions';
import {
	getDMSforREMEvent,
	getInitialSignSelectionStateFromDMSMessages,
	getSignsByIds,
	prepareDMSMessagesForClient,
	prioritizeMessages,
	setDMSPreview,
	setDMSSignLoadingPreviewIds,
} from './rem-actions-event-dms';
import { handleNewLocationDetailsForREMEvent } from './rem-actions-event-location';
import { setREMEventTimeline } from './rem-actions-event-timeline';
import {
	selectHasSufficientDataFor511Import,
	selectHasSufficientDataForDMSPreview,
	selectHasSufficientDataForDescription,
	selectHasSufficientDataForLocationDetails,
} from './rem-selectors';
import REMState from './rem-state';
import OverrideProps from '../../utils/OverrideProps';
import userHasPermission from '../user-permissions';
import Authorization from '../../rest/Authorization';
import { DRAFT_PROP_PATHS_THAT_INVALIDATE_SIGNS } from '../../config/ConfigREM';

type PreparedLocalDraftEventMod = {
	nearbyCameras: undefined;
	lat?: number;
	lon?: number;
};

type PreparedLocalDraftEvent = OverrideProps<DraftEvent, PreparedLocalDraftEventMod>;

export const prepareDraftEventForServer = (draftEvent: DraftEvent): PreparedLocalDraftEvent => ({
	...draftEvent,
	//	vehicles whose details have yet to be filled out are allowed on the front-end, but break the backend
	vehicles: draftEvent.vehicles?.filter(
		(vehicle) => isValidString(vehicle.licensePlate) || isValidString(vehicle.description),
	),
	//	responding units without a unitType and disposition are allowed on the front-end, but break the backend
	respondingUnits: draftEvent.respondingUnits?.filter((respondingUnit) =>
		isValidString(respondingUnit.unitType),
	),
	//	strip out empty lane blockage entries - they break automatic DMS messaging generation
	positiveLaneBlockage: _isNotEqual(draftEvent.positiveLaneBlockage, getEmptyLaneBlockage())
		? draftEvent.positiveLaneBlockage
		: getEmptyLaneBlockage(),
	negativeLaneBlockage: _isNotEqual(draftEvent.negativeLaneBlockage, getEmptyLaneBlockage())
		? draftEvent.negativeLaneBlockage
		: getEmptyLaneBlockage(),
	//	these are always defined by the server, the frontend can't change them
	nearbyCameras: undefined,
	selectedSigns: draftEvent.selectedSigns,
});

export const prepareDraftEventForPreviewCreation = (
	event: DraftEvent,
): Partial<CreatePreviewDto> => ({
	selectedSigns: !userHasPermission(UserPermissions.REM_CAN_CREATE_EVENTS_LIMITED)
		? event.selectedSigns
		: [],
	eventType: event.eventType,
	route: event.route,
	startMileMarker: event.startMileMarker,
	endMileMarker: event.endMileMarker,
	positiveLaneBlockage: _isNotEqual(event.positiveLaneBlockage, getEmptyLaneBlockage())
		? event.positiveLaneBlockage
		: getEmptyLaneBlockage(),
	positiveLaneBlockageType: event.positiveLaneBlockageType,
	negativeLaneBlockage: _isNotEqual(event.negativeLaneBlockage, getEmptyLaneBlockage())
		? event.negativeLaneBlockage
		: getEmptyLaneBlockage(),
	negativeLaneBlockageType: event.negativeLaneBlockageType,
	startTime: event.dateStart,
	eventId: event.id,
});

export const prepareServerEventForClient = (event: RoadEventDto): DraftEvent => {
	const positiveLaneBlockage = event.positiveLaneBlockage ?? getEmptyLaneBlockage();
	positiveLaneBlockage.lanesAffected = positiveLaneBlockage.lanesAffected ?? [];
	const positiveLaneBlockageType =
		event.positiveLaneBlockageType ?? ConfigREMEventForm.affectedLanes.defaultLaneBlockageType;

	const negativeLaneBlockage = event.negativeLaneBlockage ?? getEmptyLaneBlockage();
	negativeLaneBlockage.lanesAffected = negativeLaneBlockage.lanesAffected ?? [];
	const negativeLaneBlockageType =
		event.negativeLaneBlockageType ?? ConfigREMEventForm.affectedLanes.defaultLaneBlockageType;

	return {
		...event,
		positiveLaneBlockage,
		positiveLaneBlockageType,
		negativeLaneBlockage,
		negativeLaneBlockageType,
		selectedSigns: event.selectedSigns === null ? undefined : event.selectedSigns,
		emailEnabled: false,
	};
};

export type GetREMEvents = Action<typeof REMActionType.GET_REM_EVENTS>;
export interface SetREMEvents extends Action<typeof REMActionType.SET_REM_EVENTS> {
	events: REMState['events'];
}

export interface GetREMEventFromEvents
	extends Action<typeof REMActionType.GET_REM_EVENT_FROM_EVENTS> {
	eventId: number;
}

export interface GetREMEvent extends Action<typeof REMActionType.GET_REM_EVENT> {
	eventId: number;
}

export interface SetREMEvent extends Action<typeof REMActionType.SET_REM_EVENT> {
	event: RoadEventDto;
}

export type GetREMEventFields = Action<typeof REMActionType.GET_REM_EVENT_FIELDS>;

export interface SetREMEventFields extends Action<typeof REMActionType.SET_REM_EVENT_FIELDS> {
	eventFields: REMState['eventFields'];
}

export interface CreateREMEvent extends Action<typeof REMActionType.CREATE_REM_EVENT> {
	draftEvent: REMState['draftEvent'];
}
export interface SetREMDraftEvent extends Action<typeof REMActionType.SET_REM_DRAFT_EVENT> {
	draftEvent: REMState['draftEvent'];
}

export interface UpdateREMEvent extends Action<typeof REMActionType.UPDATE_REM_EVENT> {
	draftEvent: REMState['draftEvent'];
}

export interface SetDraftEventProp extends Action<typeof REMActionType.SET_REM_DRAFT_EVENT_PROP> {
	path: string;
	value: unknown;
}

export type REMEventUpdated = Action<typeof REMActionType.REM_EVENT_UPDATED>;

export interface ShowREMEventSection extends Action<typeof REMActionType.SHOW_REM_EVENT_SECTION> {
	section: keyof typeof REM_FORM_SECTIONS;
}

export interface HideREMEventSection extends Action<typeof REMActionType.HIDE_REM_EVENT_SECTION> {
	section: keyof typeof REM_FORM_SECTIONS;
}

export interface SetREMReadOnlyMode extends Action<typeof REMActionType.SET_REM_READ_ONLY_MODE> {
	readOnlyMode: REMState['readOnlyMode'];
}

export interface SetREMLoading extends Action<typeof REMActionType.SET_REM_LOADING> {
	loading: REMState['loading'];
}

export interface SetDispositionError extends Action<typeof REMActionType.SET_DISPOSITION_ERROR> {
	dispositionError: REMState['dispositionError'];
}

export interface GetREMEventDescription
	extends Action<typeof REMActionType.GET_REM_DRAFT_EVENT_DESCRIPTION_PREVIEW> {
	event: RoadEventDto;
}

export interface SetREMEventDescription
	extends Action<typeof REMActionType.SET_REM_DRAFT_EVENT_DESCRIPTION_PREVIEW> {
	description?: string;
}

export interface SetREMUserHasModifiedPriority
	extends Action<typeof REMActionType.SET_REM_USE_HAS_MODIFIED_PRIORITY> {
	modified: boolean;
}

export interface SetREMUserHasModifiedIncludeInEventFeed
	extends Action<typeof REMActionType.SET_REM_USER_HAS_MODIFIED_INCLUDE_IN_EVENT_FEED> {
	modified: boolean;
}

export const setREMUserHasModifiedIncludeInEventFeed = (
	modified: boolean,
): SetREMUserHasModifiedIncludeInEventFeed => ({
	type: REMActionType.SET_REM_USER_HAS_MODIFIED_INCLUDE_IN_EVENT_FEED,
	modified,
});

export const setREMEventDescription = (description?: string): SetREMEventDescription => ({
	type: REMActionType.SET_REM_DRAFT_EVENT_DESCRIPTION_PREVIEW,
	description,
});

export interface GetREMDraftEventIcon
	extends Action<typeof REMActionType.GET_REM_DRAFT_EVENT_ICON> {
	event: RoadEventDto;
}

export interface SetREMDraftEventIcon
	extends Action<typeof REMActionType.SET_REM_DRAFT_EVENT_ICON> {
	icon?: string;
}

export const setREMDraftEventIcon = (icon?: string): SetREMDraftEventIcon => ({
	type: REMActionType.SET_REM_DRAFT_EVENT_ICON,
	icon,
});

const debouncedGetDMSforREMEvent = debounce(() => {
	store.dispatch(setDMSSignLoadingPreviewIds([]));
	const state = store.getState();
	if (state.rem.draftEvent !== undefined) {
		return store.dispatch(getDMSforREMEvent(state.rem.draftEvent, true));
	}
	return undefined;
}, 100);

export const getEventDescription =
	(event: Partial<RoadEventDto>): ThunkActionRoot<Promise<APIRequestReturn | void>> =>
	async (dispatch): Promise<APIRequestReturn | void> => {
		dispatch({
			type: REMActionType.GET_REM_DRAFT_EVENT_DESCRIPTION_PREVIEW,
		});

		if (!CARS_EVENT_INTEGRATION_ENABLED) {
			return;
		}

		const url = APIConfig.eventPreviewAPI;
		const processedEvent = { ...event };

		if (!isValidNumber(processedEvent.startMileMarker)) {
			delete processedEvent.startMileMarker;
			delete processedEvent.geometry;
		}

		const apiRequestReturn = await APIRequest(
			new Request(url, {
				method: HTTPMethod.POST,
				body: JSON.stringify(processedEvent),
				headers: {
					'Content-Type': 'application/json',
				},
			}),
			APIConfig.requestTimeoutMs,
			false,
		);

		try {
			const description: { eventPreview: string } = (await apiRequestReturn.response
				?.clone()
				.json()) as { eventPreview: string };

			dispatch(setREMEventDescription(description.eventPreview));
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`error getting description for event: `, error);
			}
		}
	};

export interface SetREMDraftEventFutureIcon
	extends Action<REMActionType.SET_REM_DRAFT_EVENT_FUTURE_ICON> {
	futureIcon?: string;
}

export const setREMDraftEventFutureIcon = (futureIcon?: string): SetREMDraftEventFutureIcon => ({
	type: REMActionType.SET_REM_DRAFT_EVENT_FUTURE_ICON,
	futureIcon,
});

export interface AddREMEventIconToRecord
	extends Action<REMActionType.ADD_REM_EVENT_ICON_TO_RECORD> {
	eventId: number;
	icon?: string;
}

export const addREMEventIconToRecord = (
	eventId: number,
	icon?: string,
): AddREMEventIconToRecord => ({
	type: REMActionType.ADD_REM_EVENT_ICON_TO_RECORD,
	eventId,
	icon,
});

export const getDraftEventIcon =
	(event: DraftEvent): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({ type: REMActionType.GET_REM_DRAFT_EVENT_ICON });

		const url = new URL(ConfigREMApi.eventIconBase(), APIConfig.endpointURLBase);

		const defaultIconRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.POST,
				headers: new Headers({
					'Authorization': Authorization.JWT ?? '',
					'Content-Type': 'application/json',
				}),
				body: JSON.stringify(event),
			}),
		);

		if (defaultIconRequestReturn.response?.status === 401) {
			void dispatch(navigate(ConfigCARSx.Pages[AppSection.LOGIN].route));
		}
		if (defaultIconRequestReturn.response?.status === 404) {
			dispatch(
				showMainBanner(NotificationErrorType.ERROR, {
					title: `Error occured when fetching event icon`,
				}),
			);
			return defaultIconRequestReturn;
		}
		if (defaultIconRequestReturn.response?.status === 500) {
			dispatch(
				showMainBanner(NotificationErrorType.ERROR, {
					title: `Server error occured when fetching event icon`,
				}),
			);
			return defaultIconRequestReturn;
		}
		if (defaultIconRequestReturn.response?.ok === false) {
			return defaultIconRequestReturn;
		}
		try {
			const icon: string = (await defaultIconRequestReturn.response?.text()) ?? '';

			if (isSvg(icon)) {
				dispatch(setREMDraftEventIcon(icon));
			} else {
				dispatch(
					showMainBanner(NotificationErrorType.ERROR, {
						title: `Server returned invalid SVG string for event icon of type: ${
							event.eventType ?? 'null'
						}`,
					}),
				);
			}

			let futureIcon;
			const shouldRequestFutureIcon =
				event.dateStart && event.dateStart - new Date().getTime() > FUTURE_EVENT_ICON_THRESHOLD;

			if (shouldRequestFutureIcon) {
				url.searchParams.append('iconType', 'future');
				url.searchParams.append('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);
				const futureIconRequestReturn = await APIRequest(
					new Request(url.href, {
						method: HTTPMethod.POST,
						headers: new Headers({
							'Authorization': Authorization.JWT ?? '',
							'Content-Type': 'application/json',
						}),
						body: JSON.stringify(event),
					}),
				);
				futureIcon = (await futureIconRequestReturn.response?.text()) as string;

				if (!isSvg(futureIcon)) {
					futureIcon = undefined;

					dispatch(
						showMainBanner(NotificationErrorType.ERROR, {
							title: `Server returned invalid SVG string for future event icon of type: ${
								event.eventType ?? 'null'
							}`,
						}),
					);
				}
			}
			dispatch(setREMDraftEventFutureIcon(futureIcon));
		} catch (error) {
			defaultIconRequestReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`error fetching icon for draft event:`, error);
			}
		}

		return defaultIconRequestReturn;
	};

const updateDraftIcon = async (
	dispatch: ThunkDispatch<RootState, void, RootAction>,
	getState: () => RootState,
	path: string,
): Promise<void> => {
	const event = getState().rem.draftEvent;

	if (DRAFT_PROP_PATHS_THAT_INVALIDATE_ICON.includes(path) && event !== undefined) {
		if (DebuggingConfig.showConsoleLogs) {
			console.warn(`icon refreshed due to: ${path}`);
		}

		if (event?.eventType) {
			await dispatch(getDraftEventIcon(event));
		} else {
			dispatch(setREMDraftEventIcon(undefined));
			dispatch(setREMDraftEventFutureIcon(undefined));
		}
	}
};

export const tryRefreshDMSPreviews = async (
	dispatch: ThunkDispatch<RootState, void, RootAction>,
	getState: () => RootState,
	path: string,
) => {
	const state = getState();
	if (
		DRAFT_PROP_PATHS_THAT_INVALIDATE_SIGNS.includes(path) &&
		selectHasSufficientDataForDMSPreview(state)
	) {
		// eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
		await dispatch(setREMDraftEventProp('selectedSigns', []));
	}
	if (
		DRAFT_PROP_PATHS_THAT_INVALIDATE_DMS_PREVIEW.includes(path) &&
		selectHasSufficientDataForDMSPreview(state)
	) {
		// eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
		clearDMSPreviews();

		await debouncedGetDMSforREMEvent();
	}
};

const updateDraftLocationDetails = async (
	dispatch: ThunkDispatch<RootState, void, RootAction>,
	getState: () => RootState,
	path: string,
): Promise<void> => {
	const event = getState().rem.draftEvent;
	const state = getState();

	if (
		event !== undefined &&
		DRAFT_PROP_PATHS_THAT_INVALIDATE_LOCATION_DETAILS.includes(path) &&
		state.rem.mileMarkersValidForRoute === true &&
		selectHasSufficientDataForLocationDetails(state)
	) {
		if (DebuggingConfig.showConsoleLogs) {
			console.warn(`location details refreshed due to: ${path}`);
		}

		const { route, startMileMarker, endMileMarker } = event;
		await handleNewLocationDetailsForREMEvent(dispatch, route, startMileMarker, endMileMarker);
	}
};

const updateDraftDescription = (
	dispatch: ThunkDispatch<RootState, void, RootAction>,
	getState: () => RootState,
	path: string,
): void => {
	const state = getState();
	const event = state.rem.draftEvent;
	const geometry = state.rem.draftEventGeometryString;

	if (
		DRAFT_PROP_PATHS_THAT_INVALIDATE_DESCRIPTION.includes(path) &&
		selectHasSufficientDataForDescription(state) &&
		event !== undefined &&
		event.eventType !== undefined
	) {
		if (DebuggingConfig.showConsoleLogs) {
			console.warn(`description preview refreshed due to: ${path}`);
		}
		// if user has modified this value, leave unchanged, otherwise set to default configured value for the event type
		const includeInEventFeed =
			selectHasSufficientDataFor511Import(state) &&
			(state.rem.userHasModifiedIncludeInEventFeed && path !== 'eventType'
				? event.includeInEventFeed
				: EventTypeConfig[event.eventType]?.importTo511);

		void dispatch(
			getEventDescription({
				...event,
				geometry: geometry ? stringify(geometry as GeoJSONGeometry) : '',
			}),
		);
		void dispatch(setREMDraftEventProp('includeInEventFeed', includeInEventFeed));
	}
	if (
		DRAFT_PROP_PATHS_THAT_INVALIDATE_DESCRIPTION.includes(path) &&
		!selectHasSufficientDataForDescription(state)
	) {
		dispatch(setREMEventDescription(''));
		void dispatch(setREMDraftEventProp('includeInEventFeed', false));
	}
};

export const setREMDraftEventProp =
	(path: string, value: unknown, triggerUpdates = true): ThunkActionRoot<Promise<void>> =>
	async (dispatch, getState): Promise<void> => {
		let state = getState();
		const draftEvent = state.rem?.draftEvent;

		if (draftEvent !== undefined && (get(draftEvent, path) !== value || triggerUpdates)) {
			dispatch({
				type: REMActionType.SET_REM_DRAFT_EVENT_PROP,
				path,
				value,
			});

			if (triggerUpdates) {
				state = getState();

				void updateDraftLocationDetails(dispatch, getState, path);

				await updateDraftDescription(dispatch, getState, path);

				void tryRefreshDMSPreviews(dispatch, getState, path);

				void updateDraftIcon(dispatch, getState, path);
			}
		}
	};

export const clearDMSPreviews = () => {
	// refreshing DMS, clear out existing selected signs and previews
	store.dispatch(setDMSPreview(undefined));
};

export const setREMLoading = (loading?: boolean): SetREMLoading => ({
	type: REMActionType.SET_REM_LOADING,
	loading,
});

export const getEventFields =
	(): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({ type: REMActionType.GET_REM_EVENT_FIELDS });
		const apiRequestReturn = await APIRequest(
			new Request(new URL(ConfigREMApi.fields(), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);

		try {
			if (apiRequestReturn.response?.ok) {
				const eventFields: RoadEventFieldDto =
					(await apiRequestReturn.response.json()) as RoadEventFieldDto;
				dispatch({
					type: REMActionType.SET_REM_EVENT_FIELDS,
					eventFields,
				});
			}
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`error parsing event fields:`, error);
			}
		}
		return apiRequestReturn;
	};

export const setREMDraftEvent = (draftEvent?: DraftEvent): SetREMDraftEvent => ({
	type: REMActionType.SET_REM_DRAFT_EVENT,
	draftEvent,
});

export const setREMReadOnlyMode = (readOnlyMode: REMState['readOnlyMode']): SetREMReadOnlyMode => ({
	type: REMActionType.SET_REM_READ_ONLY_MODE,
	readOnlyMode,
});

export const startNewREMDraftEvent =
	(event?: REMState['draftEvent']): ThunkActionRoot<REMState['draftEvent']> =>
	(dispatch): REMState['draftEvent'] => {
		const initDraftEvent = event ?? getEmptyDraftEvent();
		dispatch(setREMDraftEvent(initDraftEvent));
		return initDraftEvent;
	};

export const getREMEvents =
	(): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({
			type: REMActionType.GET_REM_EVENTS,
		});
		const apiRequestReturn = await APIRequest(
			new Request(new URL(ConfigREMApi.events(), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);

		let events: RoadEventDto[];

		try {
			if (apiRequestReturn.response?.ok) {
				events = (await apiRequestReturn.response.json()) as RoadEventDto[];
				dispatch({
					type: REMActionType.SET_REM_EVENTS,
					events,
				});
			}
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`error parsing REM events:`, error);
			}
		}
		return apiRequestReturn;
	};

//	TODO: would this be more appropriate as a selector?
export const getREMEventFromEvents =
	(eventId: number): ThunkActionRoot<void> =>
	(dispatch, getState): void => {
		dispatch({ type: REMActionType.GET_REM_EVENT_FROM_EVENTS, eventId });
		const { events } = getState().rem;
		if (events) {
			const targetEvent = events?.find((event) => event.id === eventId);
			if (targetEvent) {
				const { draftEvent } = getState().rem;
				if (!draftEvent?.id) {
					dispatch(setREMDraftEvent(prepareServerEventForClient(targetEvent)));
				}
			} else {
				// eslint-disable-next-line no-lonely-if
				if (DebuggingConfig.showConsoleLogs) {
					console.warn(`unable to preload event data for event #${eventId}`);
				}
			}
		}
	};

export const getREMEvent =
	(eventId: number): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({
			type: REMActionType.GET_REM_EVENT,
			eventId,
		});
		const apiRequestReturn = await APIRequest(
			new Request(new URL(ConfigREMApi.event(eventId), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);

		if (apiRequestReturn.response?.status === 404) {
			void dispatch(navigate(`${ConfigCARSx.Pages[AppSection.REM].route}/${REMView.event}/new`));
			return apiRequestReturn;
		}
		try {
			const event: RoadEventDto = (await apiRequestReturn.response?.clone().json()) as RoadEventDto;
			const clientDraftEvent = prepareServerEventForClient(event);

			dispatch(setREMDraftEvent(clientDraftEvent));
			void dispatch(getEventDescription(event));

			if (event.eventStatus === EventStatus.COMPLETED) {
				dispatch(setREMReadOnlyMode(true));
			}
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`error parsing event #${eventId}:`, error);
			}
		}
		return apiRequestReturn;
	};

//	first check the event timeline, to see if there's a new entry -
//	and if there is, then pull the latest event record
export const refreshREMEvent =
	(eventId: number): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch, getState): Promise<APIRequestReturn> => {
		//	TODO: refactor the polling logic a bit to simplify sequence of requests
		dispatch({
			type: REMActionType.GET_REM_EVENT_TIMELINE,
			eventId,
		});
		const apiRequestReturn: APIRequestReturn = await APIRequest(
			new Request(new URL(ConfigREMApi.eventTimeline(eventId), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);

		let eventShouldPoll = true;
		let invalidTimeline = false;
		let eventTimeline: REMState['eventTimeline'] | APIErrorJSON;

		//	try parsing response

		try {
			eventTimeline = (await apiRequestReturn.response
				?.clone()
				.json()) as REMState['eventTimeline'];
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`error parsing event timeline for event #${eventId}:`, error);
			}
			invalidTimeline = true;
		}

		//	is response useable?

		if (eventTimeline === undefined || isAPIErrorJSON(eventTimeline)) {
			apiRequestReturn.apiError = APIError.ServerError;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`server error fetching timeline for event #${eventId}:`, eventTimeline);
			}
			invalidTimeline = true;
		} else if (eventTimeline?.timeline?.entries?.length === 0) {
			apiRequestReturn.apiError = APIError.ResponseEmpty;
			if (DebuggingConfig.showConsoleLogs) {
				console.warn(`warning: timeline endpoint returned an empty result for event #${eventId}`);
			}
			invalidTimeline = true;
		}

		//	are there new timeline entries?

		const { eventTimeline: previousEventTimeline } = getState().rem;
		if (
			previousEventTimeline &&
			previousEventTimeline.timeline?.entries?.[0].timestamp ===
				(eventTimeline as RoadEventTimelineDto).timeline?.entries?.[0].timestamp
		) {
			eventShouldPoll = false; //	most recent timestamp is the same, so don't bother polling
		}

		//	set timeline data accordingly

		if (invalidTimeline) {
			dispatch(setREMEventTimeline(undefined));
		} else {
			dispatch(setREMEventTimeline(eventTimeline as REMState['eventTimeline'])); //	update state with new timeline data
		}

		//	and fetch updated event record if warranted

		const { unsavedDraftEvent } = getState().rem;

		if (eventShouldPoll && !unsavedDraftEvent) {
			await dispatch(getREMEvent(eventId));

			await dispatch(getREMEvents());

			const { draftEvent } = getState().rem;

			if (draftEvent && draftEvent.id) {
				await Promise.all([
					dispatch(getDMSforREMEvent(draftEvent)),
					handleNewLocationDetailsForREMEvent(
						dispatch,
						draftEvent.route,
						draftEvent.startMileMarker,
						draftEvent.endMileMarker,
					),
				]);
			}
		}

		return apiRequestReturn;
	};

export const createREMEvent =
	(draftEvent: DraftEvent): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({
			type: REMActionType.CREATE_REM_EVENT,
			draftEvent,
		});
		dispatch(addItem('api', 'rem-create-event'));
		dispatch(setREMLoading(true));

		if (userHasPermission(UserPermissions.REM_CAN_CREATE_EVENTS_LIMITED)) {
			draftEvent.includeInEventFeed = false;
		}

		const preparedDraftEvent = prepareDraftEventForServer(draftEvent);
		const apiRequestReturn = await APIRequest(
			new Request(
				new Request(new URL(ConfigREMApi.createOrUpdate(), APIConfig.endpointURLBase).href, {
					method: HTTPMethod.POST,
					headers: new Headers({
						...getAPIHeaders(),
					}),
					body: JSON.stringify(preparedDraftEvent),
				}),
			),
		);

		if (apiRequestReturn.response?.status !== 200) {
			dispatch(setREMLoading(false));

			const apiError = (await apiRequestReturn.response?.json()) as APIErrorJSON;
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{
						title: `Error creating incident.`,
						messages: [
							`Server status code: ${apiError.status}`,
							`Error message: ${apiError.message}`,
						],
					},
					5000,
				),
			);
			return apiRequestReturn;
		}

		let roadwayEventMessages: RoadwayEventMessagesDto;
		try {
			roadwayEventMessages = (await apiRequestReturn.response
				?.clone()
				.json()) as RoadwayEventMessagesDto;
		} catch (error) {
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{ title: `Error creating incident: `, messages: [`Error message: ${error as string}`] },
					5000,
				),
			);
			dispatch(removeItem('api', 'rem-create-event'));
			dispatch(setREMLoading(false));
			return apiRequestReturn;
		}

		const { eventDto, eventMessages } = roadwayEventMessages;

		dispatch(setREMDraftEvent(prepareServerEventForClient(eventDto)));
		if (eventMessages) {
			const eventDMSPreviewMessages = prepareDMSMessagesForClient(eventMessages);
			dispatch(setDMSPreview(eventDMSPreviewMessages));
			const eventDMSSelectedSigns =
				getInitialSignSelectionStateFromDMSMessages(eventDMSPreviewMessages);
			void dispatch(setREMDraftEventProp('selectedSigns', eventDMSSelectedSigns));
		}
		let signs: SignDto[] = [];
		if (draftEvent.selectedSigns) {
			// TODO: call api/sign instead, passing in sign-ids as a query parameter
			//		 remove this getSignsByIds actions
			signs = await dispatch(
				getSignsByIds(draftEvent.selectedSigns.map((selection) => selection.signId)),
			);
		}
		const erroredSigns = signs.filter((sign) => sign.currentMessageState === MessageStates.ERROR);
		const successfulSigns = signs.filter((sign) => sign.currentMessageState === MessageStates.SENT);

		dispatch({ type: REMActionType.REM_EVENT_UPDATED });
		dispatch(
			showMainBanner(
				NotificationErrorType.SUCCESS,
				{
					title: `Incident #${eventDto.id} created successfully`,
					messages: successfulSigns.map(
						(sign) => `successfully posted to DMS ${sign.name ?? sign.id}`,
					),
				},
				5000,
			),
		);
		if (erroredSigns.length) {
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{
						title: `Error posting messages to signs`,
						messages: erroredSigns.map(
							(sign) => `failed to post message to DMS ${sign.name ?? sign.id}`,
						),
					},
					5000,
				),
			);
		}

		dispatch(removeItem('api', 'rem-create-event'));
		await dispatch(refreshREMEvent(eventDto.id));
		await dispatch(getEventDescription(eventDto));
		dispatch(setREMLoading(false));

		return apiRequestReturn;
	};

export const updateREMEvent =
	(
		draftEvent: DraftEvent,
		redirect: string | false = false,
	): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({
			type: REMActionType.UPDATE_REM_EVENT,
			draftEvent,
		});
		dispatch(addItem('api', 'rem-update-event'));
		dispatch(setREMLoading(true));

		const preparedDraftEvent = prepareDraftEventForServer(draftEvent);
		const now = new Date().getTime();

		if (preparedDraftEvent.eventStatus === EventStatus.COMPLETED) {
			preparedDraftEvent.respondingUnits = (
				preparedDraftEvent.respondingUnits as RespondingUnitDto[]
			)?.map((unit) => ({
				...unit,
				assigned: unit.assigned ?? now,
				arrived: unit.arrived ?? now,
				completed: unit.completed ?? now,
			}));
		}

		const apiRequestReturn = await APIRequest(
			new Request(
				new Request(new URL(ConfigREMApi.createOrUpdate(), APIConfig.endpointURLBase).href, {
					method: HTTPMethod.POST,
					headers: new Headers({
						...getAPIHeaders(),
					}),
					body: JSON.stringify(preparedDraftEvent),
				}),
			),
		);

		if (apiRequestReturn.response?.status === HttpStatusCode.CONFLICT) {
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{
						title: `Error updating incident: `,
						messages: [`A more recent version of this form has been submitted by another user.`],
					},
					5000,
				),
			);
			dispatch(setREMLoading(false));
			return apiRequestReturn;
		}

		if (apiRequestReturn.response?.status !== 200) {
			dispatch(setREMLoading(false));

			const apiError = (await apiRequestReturn.response?.json()) as APIErrorJSON;
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{
						title: `Error updating incident.`,
						messages: [
							`Server status code: ${apiError.status}`,
							`Error message: ${apiError.message}`,
						],
					},
					5000,
				),
			);
			return apiRequestReturn;
		}

		let roadwayEventMessages: RoadwayEventMessagesDto;
		try {
			roadwayEventMessages = (await apiRequestReturn.response
				?.clone()
				.json()) as RoadwayEventMessagesDto;
		} catch (error) {
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{ title: `Error updating incident: `, messages: [`Error message: ${error as string}`] },
					5000,
				),
			);
			dispatch(setREMLoading(false));
			dispatch(removeItem('api', 'rem-update-event'));
			return apiRequestReturn;
		}

		const { eventDto, eventMessages } = roadwayEventMessages;

		// re-prioritize messages if the refresh button was clicked, otherwise update the messages normally
		if (store.getState().rem.shouldRefreshEventMessages && draftEvent.id) {
			await dispatch(prioritizeMessages(draftEvent.id, true));
		} else if (eventMessages) {
			const eventDMSPreviewMessages = prepareDMSMessagesForClient(eventMessages);
			dispatch(setDMSPreview(eventDMSPreviewMessages));
			const eventDMSSelectedSigns =
				getInitialSignSelectionStateFromDMSMessages(eventDMSPreviewMessages);
			void dispatch(setREMDraftEventProp('selectedSigns', eventDMSSelectedSigns));
		}

		if (redirect) {
			dispatch(
				showMainBanner(
					NotificationErrorType.SUCCESS,
					{ title: `Incident #${eventDto.id} completed successfully` },
					5000,
				),
			);
			await dispatch(navigate(redirect));
			dispatch(setREMLoading(false));
			return apiRequestReturn;
		}

		dispatch(setREMDraftEvent(prepareServerEventForClient(eventDto)));

		dispatch({ type: REMActionType.REM_EVENT_UPDATED });
		dispatch(
			showMainBanner(
				NotificationErrorType.SUCCESS,
				{ title: `Incident #${eventDto.id} updated successfully` },
				5000,
			),
		);

		if (eventDto.eventStatus !== EventStatus.COMPLETED) {
			await dispatch(refreshREMEvent(eventDto.id));
			await dispatch(getEventDescription(eventDto));
		}

		dispatch(setREMLoading(false));
		dispatch(removeItem('api', 'rem-update-event'));
		return apiRequestReturn;
	};

export const showREMEventSection = (
	section: keyof typeof REM_FORM_SECTIONS,
): ShowREMEventSection => ({
	type: REMActionType.SHOW_REM_EVENT_SECTION,
	section,
});

export const hideREMEventSection = (
	section: keyof typeof REM_FORM_SECTIONS,
): HideREMEventSection => ({
	type: REMActionType.HIDE_REM_EVENT_SECTION,
	section,
});
